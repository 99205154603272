<template>
  <el-row class="top-title">
    <el-col :span="12">
      <div class="title" style="padding-left: 10px">累计运营里程（KM）</div>
      <FlipNumber :number="number1" />
    </el-col>
    <el-col :span="12">
      <div class="title">累计运营车辆（台）</div>
      <FlipNumber :number="number2" />
    </el-col>
  </el-row>
</template>
<script>
import FlipNumber from "@/components/flipOverEffect/FlipNumber.vue";
import { getCumulativeData } from "@/api/dataAnalysis/largeScreen";
export default {
  name: "countNumber",
  data() {
    return {
      number1: 0,
      number2: 0,
    };
  },
  components: {
    FlipNumber,
  },
  methods: {},
  async mounted() {
    try {
      const {
        data: { cumulativeMileage, cumulativeVehicle },
      } = await getCumulativeData();
      this.number1 = Number(cumulativeMileage);
      this.number2 = Number(cumulativeVehicle);
    } catch (error) {
      this.number1 = 0;
      this.number2 = 0;
    }
  },
};
</script>
<style scoped lang="less">
.top-title {
  height: 130px;
  text-align: center;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 15px;
  line-height: 58px;
  font-family: GenJyuuGothic-ExtraLight;
}
/deep/.el-col-12{
  margin-top: -20px;
}
.text {
  font-size: 44px;
  font-weight: 600;
  color: #fff;
  font-family: GenJyuuGothic-ExtraLight;

  .inner {
    display: inline-block;
    float: left;
    margin-right: 13px;
    width: 36px;
    height: 52px;
    background: url("../../../assets/screen/num.png") center center;
    background-size: cover;
  }
}
</style>
