<template>
  <div style="position: relative; width: 100%; height: 100%">
    <div id="carImport" style="width: 100%; height: 100%"></div>
    <img
      @click="goto(index)"
      v-for="(item, index) in 8"
      :style="{ top: index * 30.5 + 27 + 'px' }"
      class="imgs"
      src="../../../assets/screen/goto.png"
      alt=""
    />
  </div>
</template>
<script>
import * as echarts from "echarts";
import { getVehicleDistributionData } from "@/api/dataAnalysis/largeScreen";
export default {
  name: "carImport",
  data() {
    return {
      mapObjChart: null,
      countryColors: ["#FD406B", "#FFA122", "#63FF08"],
      list: [],
    };
  },
  methods: {
    goto(index) {
      console.log(index, 111);
    },
    async initChart() {
      const el = document.getElementById("carImport");
      const arr = [["amount", "product"]];
      this.list.map((item) => {
        const { parkName, vehicleNumbers } = item;
        arr.push([vehicleNumbers, parkName]);
      });
      let option = {
        dataset: {
          source: arr,
        },
        grid: {
          show: false,
          containLabel: true,
          top: 20,
          left: 0,
          right: 90,
          bottom: 20,
        },
        xAxis: { name: "amount", splitLine: { show: false }, show: false },
        yAxis: {
          type: "category",
          axisLabel: {
            color: "#B8CDD9",
          },
          inverse: true,
          axisTick: { show: false },
        },
        series: [
          {
            type: "bar",
            encode: {
              y: "product",
            },
            label: {
              show: true,
              position: "right",
              color: "#fff",
              formatter: `{@amount}（台）`,
            },
            itemStyle: {
              color: (param) => {
                return (
                  this.countryColors[param.dataIndex] ||
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: "#00FFB2" },
                    { offset: 1, color: "#0061FF" },
                  ])
                );
              },
            },
            barWidth: 17,
          },
        ],
      };
      if (!echarts.getInstanceByDom(el)) {
        this.mapObjChart = echarts.init(el);
      }
      this.mapObjChart.clear();
      this.mapObjChart.setOption(option);

      window.addEventListener("resize", () => {
        this.mapObjChart.resize();
      });
    },
  },
  async mounted() {
    const { data } = await getVehicleDistributionData();
    this.list = data;
    this.initChart();
  },
};
</script>
<style scoped lang="less">
.imgs {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
</style>
