<template>
  <div class="screenBg" id="particles-js">
    <div class="ScreenAdapter" :style="style">
      <slot />
    </div>
  </div>
</template>
<script>
import particlesJs from "particles.js";
import particlesConfig from "../until/partjs.json";
export default {
  name: "ScreenAdapter",
  //参数注入
  props: {
    width: {
      type: String,
      default: "1920",
    },
    height: {
      type: String,
      default: "1080",
    },
  },
  data() {
    return {
      style: {
        width: this.width + "px",
        height: this.height + "px",
        transform: "scale(1) translate(-50%, -50%)",
      },
    };
  },
  mounted() {
    // particlesJS("particles-js", particlesConfig);
    this.setScale();
    window.onresize = this.Debounce(this.setScale, 1000);
  },
  methods: {
    Debounce: (fn, t) => {
      const delay = t || 500;
      let timer;
      return function () {
        const args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        const context = this;
        timer = setTimeout(() => {
          timer = null;
          fn.apply(context, args);
        }, delay);
      };
    },
    // 获取放大缩小比例
    getScale() {
      const w = window.innerWidth / this.width;
      const h = window.innerHeight / this.height;
      return w < h ? w : h;
    },
    // 设置比例
    setScale() {
      this.style.transform = "scale(" + this.getScale() + ") translate(-50%, -50%)";
    },
  },
};
</script>
<style lang="less" scoped>
.screenBg {
  background-color: #000;
  height: 100vh;
}
.ScreenAdapter {
  // background: url("../assets/screen/bg.png") no-repeat right top;
  // background-size: cover;
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: 0.3s;
}
#particles-js {
  // width: 100%;
  // height: 100%;
  // // position: relative;
  background-image: url(../assets/screen/bg1.png);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  // margin-left: auto;
  // margin-right: auto;
}
</style>
