<template>
  <div class="FlipClock">
    <Flipper v-for="(tiem, index) in arr" :key="index" :ref="'F' + index" />
  </div>
</template>

<script>
import Flipper from "./Flipper";

export default {
  name: "FlipClock",
  data() {
    return {
      timer: null,
      arr: [],
      once: 0,
    };
  },
  props: {
    number: {
      type: [Number, String],
      default: 0,
    },
  },
  components: {
    Flipper,
  },
  watch: {
    number(newVal, oldVal) {
      console.log(newVal)
      console.log(oldVal)
      this.fn(newVal, oldVal);
    },
  },
  methods: {
    fn(newVal, oldVal) {
      if (this.once == 0) {
        this.once++;

        let startArr = [];
        for (let i = 0; i < newVal.toString().length; i++) {
          startArr.push("0");
        }
        // 不同位数
        this.arr = this.number.toString().split("");
        this.$nextTick(() => {
          startArr.forEach((ele, i) => {
            this.$refs[`F` + i][0].setFront(ele);
          });
          setTimeout(() => {
            this.changeNumber(newVal, startArr);
          }, 500);
        });
      } else {
        // 相同位数
        if (newVal.toString().length == newVal.toString().length) {
          this.changeNumber(newVal, oldVal);
        } else {
          // 不同位数加翻牌
          this.arr = this.number.toString().split("");
          this.$nextTick(() => {
            this.arr.forEach((ele, i) => {
              this.$refs[`F` + i][0].setFront(ele);
            });
          });
        }
      }
    },
    //
    changeNumber(newVal, oldVal) {
      let newArr = newVal.toString().split("");
      let oldArr = [];
      if (oldVal instanceof Array) {
        oldArr = oldVal;
      } else {
        oldArr = oldVal.toString().split("");
      }

      for (let i = 0; i < newArr.length; i++) {
        // console.log(newArr, oldArr);
        if (newArr[i] > oldArr[i]) {
          // 大于当前数字向下翻牌
          this.$refs[`F` + i][0].flipDown(oldArr[i], newArr[i]);
        } else if (newArr[i] < oldArr[i]) {
          // 小于当前数字向上翻牌
          this.$refs[`F` + i][0].flipUp(oldArr[i], newArr[i]);
        }
      }
    },
  },
  mounted() { },
};
</script>

<style>
.FlipClock .M-Flipper {
  margin: 0 2px;
  color: #fff;
}

.FlipClock::before {
  /* Vector 29 */
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 19px;
  width: 60px;
  height: 88px;
  transform-origin: 50% 50% 0;
	transform: perspective(75px) rotateY(45deg);
  background: linear-gradient(270deg, #1BEECA 0%, #1BEECA 0%, rgba(72, 72, 72, 0.00) 100%, rgba(72, 72, 72, 0.00) 100%);
}

.FlipClock::after {
  /* Vector 29 */
  content: "";
  display: block;
  position: absolute;
  right: 0px;
  top: 19px;
  width: 60px;
  height: 88px;
  transform-origin: 50% 50% 0;
  transform: rotate(180deg) perspective(75px) rotateY(45deg);
  background: linear-gradient(270deg, #1BEECA 0%, #1BEECA 0%, rgba(72, 72, 72, 0.00) 100%, rgba(72, 72, 72, 0.00) 100%);
}
</style>
