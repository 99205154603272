<template>
  <div id="sevenOperatingTime"></div>
</template>

<script>
import * as echarts from "echarts";
import { getWeekOperationData } from "@/api/dataAnalysis/largeScreen";

export default {
  name: "",
  data() {
    return {
      charts: null,
    };
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    async initEcharts() {
      const { data } = await getWeekOperationData();
      const xAxis = [],
        series1 = [],
        series2 = [];
      data.map((item) => {
        const { date, mannedVehicleTime, utilVehicleTime } = item;
        xAxis.push(date);
        series1.push(mannedVehicleTime);
        series2.push(utilVehicleTime);
      });
      const option = {
        title: {
          text: "近7日平均每日运营时长",
          textStyle: {
            color: "#fff",
            fontSize: 21,
          },
          padding: [
            4.5, // 上
            0, // 右
            0, // 下
            32, // 左
          ],
        },
        tooltip: {
          trigger: "axis",
          renderMode: "richText",
          //  formatter: '{value} h',
          // valueFormatter: value => value + ' h'
          // formatter: function (params) {
          //   console.log(params, params[0].name);
          //   var relVal = params[0].name;
          //   relVal +=
          //     "<br/>" +
          //     `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#318B76;"></span>` +
          //     params[0].seriesName +
          //     " : " +
          //     params[0].value +
          //     " h" +
          //     "<br/>" +
          //     `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#E3A150;"></span>` +
          //     params[1].seriesName +
          //     " : " +
          //     params[1].value +
          //     " h";
          //   return relVal;
          // },
        },
        legend: {
          data: ["载人车", "功能车"],
          top: "4",
          right: "24",
          textStyle: {
            //图例文字的样式
            color: "#fff",
            fontSize: 14,
          },
        },
        grid: {
          top: "18%",
          left: "6%",
          right: "6%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {},
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#979797",
            },
          },
          boundaryGap: true,
          data: xAxis,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#09F9F2",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: { show: false },
          splitLine: {
            lineStyle: {
              color: ["#979797"],
            },
          },
          axisLabel: {
            show: true,
            formatter: "{value} h",
            textStyle: {
              color: "#09F9F2",
            },
          },
        },
        series: [
          {
            name: "载人车",
            type: "line",
            data: series1,
            symbol: "circle",
            symbolSize: 6,
            itemStyle: {
              color: "#318B76",
            },
          },
          {
            name: "功能车",
            type: "line",
            data: series2,
            symbol: "circle",
            symbolSize: 6,
            itemStyle: {
              color: "#E3A150",
            },
          },
        ],
      };
      let chartDom = document.getElementById("sevenOperatingTime");
      this.charts = echarts.init(chartDom);
      option && this.charts.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
#sevenOperatingTime {
  height: 100%;
}
</style>
