import { render, staticRenderFns } from "./carImport.vue?vue&type=template&id=38d375f7&scoped=true&"
import script from "./carImport.vue?vue&type=script&lang=js&"
export * from "./carImport.vue?vue&type=script&lang=js&"
import style0 from "./carImport.vue?vue&type=style&index=0&id=38d375f7&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d375f7",
  null
  
)

export default component.exports