<template>
  <div id="sevenOperatingMileage"></div>
</template>

<script>
import * as echarts from "echarts";
import { getWeekOperationData } from "@/api/dataAnalysis/largeScreen";

export default {
  name: "sevenOperatingMileage",
  data() {
    return {
      charts: null,
    };
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    async initEcharts() {
      const { data } = await getWeekOperationData();
      const xAxis = [],
        series1 = [],
        series2 = [];
      data.map((item) => {
        const { date, mannedVehicleMileage, utilVehicleMileage } = item;
        xAxis.push(date);
        series1.push(mannedVehicleMileage);
        series2.push(utilVehicleMileage);
      });
      const options = {
        title: {
          text: "近7日平均每日运营里程分布",
          textStyle: {
            color: "#fff",
            fontSize: 21,
          },
          padding: [
            4.5, // 上
            0, // 右
            0, // 下
            32, // 左
          ],
        },
        tooltip: {
          trigger: "axis",
          renderMode: "richText",
          // formatter: function (params) {
          //   var relVal = params[0].name;
          //   relVal +=
          //     "<br/>" +
          //     `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#318B76;"></span>` +
          //     params[0].seriesName +
          //     " : " +
          //     params[0].value +
          //     "<br/>" +
          //     `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#E3A150;"></span>` +
          //     params[1].seriesName +
          //     " : " +
          //     params[1].value;
          //   return relVal;
          // },
        },
        legend: {
          data: ["载人车", "功能车"],
          right: "24",
          top: "4",
          textStyle: {
            //图例文字的样式
            color: "#fff",
            fontSize: 14,
          },
        },
        grid: {
          top: "22%",
          left: "6%",
          right: "6%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {},
        xAxis: {
          axisLine: {
            lineStyle: {
              color: "#979797",
            },
          },
          type: "category",
          boundaryGap: true,
          data: xAxis,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#09F9F2",
            },
          },
        },
        yAxis: {
          axisLine: { show: false },
          type: "value",
          splitLine: {
            lineStyle: {
              color: ["#979797"],
            },
          },
          axisLabel: {
            textStyle: {
              color: "#09F9F2",
            },
          },
        },
        series: [
          {
            name: "载人车",
            type: "bar",
            data: series1,
            barWidth: 18,
            itemStyle: {
              color: "#3E68F2",
            },
          },
          {
            name: "功能车",
            type: "bar",
            data: series2,
            barWidth: 18,
            itemStyle: {
              color: "#29FFB7",
            },
          },
        ],
      };
      let chartDom = document.getElementById("sevenOperatingMileage");
      if (!this.charts) this.charts = echarts.init(chartDom);
      options && this.charts.setOption(options);
    },
  },
};
</script>
<style lang="less" scoped>
#sevenOperatingMileage {
  height: 100%;
}
</style>
