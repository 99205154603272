<template>
  <div>
    <el-row :gutter="18">
      <el-col
        :span="13"
        style="margin-top: 12px; height: 198px; background-color: #13141a; overflow: hidden;"
      >
        <dv-scroll-board
          :config="config"
          style="display: inline-block; margin-top: 12px; width: 400px; height: 178px"
        />
      </el-col>
      <el-col :span="11">
        <el-row style="margin-top: 48px">
          <el-col :span="8">
            <div class="text" style="color: #ff3434">一级报警</div>
            <div class="level level1">{{ firstLevelAlarmTimes }}次</div>
          </el-col>
          <el-col :span="8">
            <div class="text" style="color: #ffb100">二级报警</div>
            <div class="level level2">{{ secondLevelAlarmTimes }}次</div>
          </el-col>
          <el-col :span="8">
            <div class="text" style="color: #29FFB8">三级报警</div>
            <div class="level level3">{{ thirdLevelAlarmTimes }}次</div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { getAlarmData } from "@/api/dataAnalysis/largeScreen";
export default {
  name: "screenTable",
  data() {
    return {
      config: {
        data: [],
        oddRowBGC: "#13141a",
        evenRowBGC: "#13141a",
        columnWidth: [58, 120, 80, 160],
      },
      firstLevelAlarmTimes: 0,
      secondLevelAlarmTimes: 0,
      thirdLevelAlarmTimes: 0,
    };
  },
  components: {},
  methods: {
    async setData() {
      getAlarmData()
        .then((res) => {
          if (!res.success) {
            this.$message.error(res.message);

            return;
          }
          const {
            data: {
              vehicleAlarmInfos,
              firstLevelAlarmTimes,
              secondLevelAlarmTimes,
              thirdLevelAlarmTimes,
            },
          } = res;
          this.thirdLevelAlarmTimes = thirdLevelAlarmTimes;
          this.firstLevelAlarmTimes = firstLevelAlarmTimes;
          this.secondLevelAlarmTimes = secondLevelAlarmTimes;
          const arr = [];
          if (vehicleAlarmInfos.length) {
            vehicleAlarmInfos.map((item) => {
              const { parkName, vehicleNumber, alarmDesc } = item;
              const imgStr = `<img style="height:25px;vertical-align: middle;}"
                src="bus2.png"
                alt=""
                />`;
              const col2 = `<span style="color:#2BFFFF;">${vehicleNumber}</span>`;
              const col3 = `<span style="color:#B8CDD9;">${parkName}</span>`;
              const col4 = `<span style="color:#FF5654;">${alarmDesc}</span>`;
              arr.push([imgStr, col2, col3, col4]);
            });
          }
          this.config = {
            ...this.config,
            data: arr,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.setData();
  },
};
</script>
<style scoped lang="less">
.text {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  margin-bottom: 7px;
  margin-left: 12px;
}
.level {
  height: 94px;
  width: 94px;
  font-size: 18px;
  font-weight: 600;
  line-height: 94px;
  text-align: center;
  margin-left: 10px;
}
.level1 {
  background: url("../../../assets/screen/warning1.png") center center;
  background-size:auto 130%;
  color: #FD4070;
}
.level2 {
  background: url("../../../assets/screen/warning2.png") center center;
  background-size:auto 130%;
  color: #FFE322;
}
.level3 {
  background: url("../../../assets/screen/warning3.png") center center;
  background-size:auto 130%;
  color: #29FFB8;
}
</style>
