<template>
  <div class="echarts">
    <div style="width: 100; height: 100%" id="sctterMap"></div>
    <div class="mapChoose" v-if="level > 0">
      <span class="title" @click="resetMap"><img height="30px" src="@/assets/images/reset.png" alt="" /></span>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import resize from "./resize";
import { getLocationInfo, getMapDistributionData } from "@/api/dataAnalysis/largeScreen";
export default {
  name: "sctterMap",
  mixins: [resize],
  data() {
    return {
      myCharts: null,
      time: null,
      changAreaTime: null,
      adcode: "",
      geoJson: {
        features: [],
      },
      // show: true,
      level: 0,
      parentInfo: [
        {
          cityName: "全国",
          code: 100000,
        },
      ],
    };
  },
  mounted() {
    this.getGeoJson("");
  },
  methods: {
    resetMap() {
      // if (this.changAreaTime) {
      //   clearInterval(this.changAreaTime)
      //   this.changAreaTime = null
      // }
      if (this.level === 0) return;
      this.getGeoJson("");
      this.level = 0;
    },
    async getGeoJson(adcode) {
      if (this.level === 3) return false;
      this.adcode = adcode;
      let that = this;
      const { data } = await getLocationInfo({ locationName: adcode });
      if (data && data.locationMapInfo) {
        const Json = JSON.parse(data.locationMapInfo);
        that.geoJson = Json;
        that.getMapData();
      }
    },
    //获取数据
    async getMapData() {
      const arr = [];
      const res = await getMapDistributionData({ level: this.level, name: this.adcode });
      res.data.map((item) => {
        const { name, longitude, latitude, vehicleNumbers } = item;
        arr.push({
          name: name,
          value: [Number(longitude), Number(latitude), vehicleNumbers],
        });
      });
      let mapData = this.geoJson.features.map((item) => {
        return {
          name: item.properties.name,
          value: Math.random() * 1000,
          cityCode: item.properties.adcode,
        };
      });
      mapData = mapData.sort(function (a, b) {
        return b.value - a.value;
      });
      // if (this.changAreaTime === null) {
      this.initEcharts(mapData, arr);
      // }
      // this.changAreaTime = setInterval(() => {
      //   this.show = !this.show
      //   console.log(arr)
      //   this.initEcharts(mapData, arr);
      // }, 5000)
    },
    initEcharts(mapData, labelData = []) {
      const el = document.getElementById("sctterMap");
      this.myChart = echarts.init(el);
      echarts.registerMap("Map", this.geoJson); //注册
      const poiMarkers = [];
      labelData.map((item) => {
        // if (item.name === "江苏省" || item.name === "河南省" || item.name === "河北省") {
        //   poiMarkers.push({
        //     name: `${item.name}:${item.value[2] || 0}`,
        //     coord: [item.value[0], item.value[1]],
        //     symbol: "image://target2.png",
        //     symbolSize: this.show ?70,
        //     label: {
        //       show: this.show,
        //       formatter: `${item.value[2] || 0}辆`,
        //       color: "#FFFFFF",
        //       fontFamily: "Gen Jyuu Gothic",
        //       fontSize: 16,
        //       offset: [0, 2],
        //     },
        //   })
        // } else if (item.name === "陕西省" || item.name === "浙江省" || item.name === "北京市") {
        //   poiMarkers.push({
        //     name: `${item.name}:${item.value[2] || 0}`,
        //     coord: [item.value[0], item.value[1]],
        //     symbol: "image://target2.png",
        //     symbolSize: !this.show ? 70 : 0,
        //     label: {
        //       show: !this.show,
        //       formatter: `${item.value[2] || 0}辆`,
        //       color: "#FFFFFF",
        //       fontFamily: "Gen Jyuu Gothic",
        //       fontSize: 16,
        //       offset: [0, 2],
        //     },
        //   })
        // }
        // else {
        poiMarkers.push({
          name: `${item.name}:${item.value[2] || 0}`,
          coord: [item.value[0], item.value[1]],
          symbol: "image://target2.png",
          symbolSize: 70,
          label: {
            show: true,
            formatter: `${item.value[2] || 0}辆`,
            color: "#FFFFFF",
            fontFamily: "Gen Jyuu Gothic",
            fontSize: 16,
            offset: [0, 2],
          },
        })
        // }

      });
      this.myChart.setOption(
        {
          tooltip: {
            trigger: "item",
            renderMode: "richText",
            formatter: (p) => {
              let val = p.value;
              if (!val) {
                val = 0;
              }
              let txtCon = p.name + ":" + val.toFixed(2);
              return p.name;
            },
          },
          toolbox: {
            feature: {
              restore: {
                show: false,
              },
            },
            iconStyle: {
              normal: {
                borderColor: "#1990DA",
              },
            },
            top: 15,
            right: 35,
          },
          series: [
            {
              name: "地图",
              type: "map",
              map: "Map",
              roam: true, //是否可缩放
              zoom: 1.1, //缩放比例
              data: mapData,
              label: {
                normal: {
                  show: false,
                  color: "rgb(249, 249, 249)", //省份标签字体颜色
                  formatter: (p) => {
                    switch (p.name) {
                      case "内蒙古自治区":
                        p.name = "内蒙古";
                        break;
                      case "西藏自治区":
                        p.name = "西藏";
                        break;
                      case "新疆维吾尔自治区":
                        p.name = "新疆";
                        break;
                      case "宁夏回族自治区":
                        p.name = "宁夏";
                        break;
                      case "广西壮族自治区":
                        p.name = "广西";
                        break;
                      case "香港特别行政区":
                        p.name = "香港";
                        break;
                      case "澳门特别行政区":
                        p.name = "澳门";
                        break;
                      default:
                        break;
                    }
                    return p.name;
                  },
                },
                emphasis: {
                  show: false,
                  color: "#f75a00",
                },
              },
              itemStyle: {
                normal: {
                  areaColor: "#24CFF4",
                  borderColor: "#53D9FF",
                  borderWidth: 1.3,
                  shadowBlur: 15,
                  shadowColor: "rgb(58,115,192)",
                  shadowOffsetX: 7,
                  shadowOffsetY: 6,
                },
                emphasis: {
                  areaColor: "#8dd7fc",
                  borderWidth: 1.6,
                  shadowBlur: 25,
                },
              },
              markPoint: {
                data: poiMarkers,
              },
            },
          ],
        },
        true
      );
      let that = this;
      this.myChart.off("click");
      this.myChart.on("click", (params) => {
        if (this.time)
          clearTimeout(this.time)
        // if (this.changAreaTime)
        //   clearInterval(this.changAreaTime);
        // this.changAreaTime = null
        console.log(params)
        this.time = setTimeout(() => {
          if (this.level === 2) return;
          let data = params.data;
          that.parentInfo.push({
            cityName: data.name,
            code: data.cityCode,
          });
          let name = data.name.split(":")[0];
          that.getGeoJson(name);
          this.level++;
        }, 500)
      });
    },
    //选择切换市县
    // chooseArea(val, index) {
    //   if (this.changAreaTime)
    //     clearInterval(this.changAreaTime);
    //   this.changAreaTime = null
    //   this.getGeoJson("");
    // },
  },
};
</script>
<style lang="less" scoped>
.echarts {
  width: 100%;
  height: 508px;
  position: relative;
  //   background: url("../assets/bg1.jpg") no-repeat;
  background-size: 100% 100%;
}

.mapChoose {
  z-index: 2;
  position: absolute;
  right: 20px;
  top: 0px;
  color: #eee;

  .title {
    padding: 5px;
    cursor: pointer;
  }

  .icon {
    font-family: "simsun";
    font-size: 25px;
    margin: 0 11px;
  }
}
</style>
