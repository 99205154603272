<template>
  <ScreenAdapter>
    <div id="largeScreen">
      <div class="title">
        <img height="33px" style="margin-top: 50px" src="../../assets/screen/title.png" alt="" />
      </div>
      <el-row style="margin-top: -130px; padding: 25px" :gutter="8">
        <el-col :span="7">
          <div class="left">
            <dv-border-box-7 :color="['transparent', 'transparent']">
              <div class="l-top" :style="{ height: '266px' }">
                <div class="card_title">昨日车辆运营实况</div>
                <el-row>
                  <el-col :span="6">
                    <img style="position: relative; top: 52px; width: 95%" src="../../assets/screen/circle2.png"
                      alt="" />
                    <div :style="{
                      'text-align': 'center',
                      'margin-top': '-33px',
                      'margin-left': '-8px'
                    }">
                      <div :style="{
                        color: '#FFFFFF',
                        fontSize: '14px',
                        fontFamily: 'GenJyuuGothic-ExtraLight'
                      }">
                        <span :style="{ fontSize: '27px' }">{{
                            operationData.vehicleNumbers || 0
                        }}</span>台
                      </div>
                      <div class="carOperation">昨日运营车辆</div>
                    </div>
                  </el-col>
                  <el-col style="margin-left:12px;" :span="6">
                    <img style="position: relative; top: 52px; width: 95%" src="../../assets/screen/circle2.png"
                      alt="" />
                    <div :style="{
                      'text-align': 'center',
                      'margin-top': '-33px',
                      'margin-left': '-8px',
                    
                    }">
                      <div :style="{
                        color: '#FFFFFF',
                        fontSize: '14px',
                        fontFamily: 'GenJyuuGothic-ExtraLight'
                      }">
                        <span :style="{ fontSize: '27px' }">{{
                            operationData.vehicleMileage || 0
                        }}</span>KM
                      </div>
                      <div class="carOperation">昨日运营里程</div>
                    </div>
                  </el-col>
                  <el-col :span="9">
                    <div class="inner" style="margin: 24px 10px 13px;">
                      <div>
                        <span class="name">载人车</span><span class="text">{{ operationData.mannedVehicleNumbers || 0
                        }}台</span>
                      </div>
                      <div style="margin-top: 12px">
                        <el-row>
                          <el-col :span="7">
                            <img height="25px" src="../../assets/screen/bus1.png" alt="" />
                            <div class="car">BUS</div>
                          </el-col>
                          <el-col :span="7"><img height="25px" src="../../assets/screen/bus2.png" alt="" />
                            <div class="car">1.0观光车</div>
                          </el-col>

                          <el-col :span="10">
                            <div class="text" style="margin-top: 20px">
                              {{ operationData.mannedVehicleMileage || 0 }}KM
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                    <div class="inner" style="margin: 0px 10px 0px;">
                      <div>
                        <span class="name">功能车</span><span class="text">{{ operationData.utilVehicleNumbers || 0
                        }}台</span>
                      </div>
                      <div style="margin-top: 12px">
                        <el-row>
                          <el-col :span="7">
                            <img height="25px" src="../../assets/screen/bus3.png" alt="" />
                            <div class="car">清扫车</div>
                          </el-col>
                          <el-col :span="7"><img height="25px" src="../../assets/screen/bus4.png" alt="" />
                            <div class="car">巡逻车</div>
                          </el-col>

                          <el-col :span="10">
                            <div class="text" style="margin-top: 20px">
                              {{ operationData.utilVehicleMileage || 0 }}KM
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </dv-border-box-7>
            <dv-border-box-7 :color="['transparent', 'transparent']" class="mt-8 mb-8">
              <div class="l-middle" :style="{ height: '266px' }">
                <div class="card_title">昨日车辆分布</div>
                <yesterdayCar></yesterdayCar>
              </div>
            </dv-border-box-7>
            <dv-border-box-7 :color="['transparent', 'transparent']">
              <div class="l-bottom" :style="{ height: '302px' }">
                <div class="card_title">车辆接入分布概览（TOP8）</div>
                <carImport></carImport>
              </div>
            </dv-border-box-7>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="middle">
            <dv-border-box-7 style="position:relative;" :color="['transparent', 'transparent']">
              <div class="vector"></div>
              <div class="img1"></div>
              <div class="img2"></div>
              <div class="img3"></div>
              <countNumber></countNumber>
            </dv-border-box-7>
            <dv-border-box-7 class="mt-8 mb-8" :color="['#2AFFFF', '#2AFFFF']" backgroundColor="#00000073">
              <div style="position: relative" class="m-top" :style="{ height: '535px' }">
                <!-- <img
                  style="
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    opacity: 0.15;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                  "
                  src="../../assets/screen/skybox.gif"
                  alt=""
                /> -->
                <div class="card_title" style="position: relative; z-index: 1; top: 22px; left: 30px">
                  全国车辆分布总览
                </div>
                <!-- <iframe
                  src="http://localhost:8080/#/CsiumMap"
                  style="height: 507px; width: 775px"
                  frameborder="0"
                ></iframe> -->
                <mapChart></mapChart>
              </div>
            </dv-border-box-7>
            <dv-border-box-7 :color="['transparent', 'transparent']">
              <div class="m-bottom" :style="{ height: '219px' }">
                <screenTable></screenTable>
              </div>
            </dv-border-box-7>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="right">
            <dv-border-box-7 :color="['transparent', 'transparent']">
              <div class="r-top" :style="{ height: '266px' }">
                <!-- <div class="card_title">近7日平均每日运营时长</div> -->
                <div class="card_title" style="position: absolute"></div>
                <sevenOperatingTime></sevenOperatingTime>
              </div>
            </dv-border-box-7>
            <dv-border-box-7 class="mt-8 mb-8" :color="['transparent', 'transparent']">
              <div class="r-middle" :style="{ height: '266px' }">
                <!-- <div class="card_title">近7日平均每日运营里程分布</div> -->
                <div class="card_title" style="position: absolute"></div>
                <sevenOperatingMileage></sevenOperatingMileage>
              </div>
            </dv-border-box-7>
            <dv-border-box-7 :color="['transparent', 'transparent']">
              <div class="r-bottom" :style="{ height: '302px' }">
                <!-- <div class="card_title">累计运营里程分布概览</div> -->
                <div class="card_title" style="position: absolute"></div>
                <addOperatingMileage></addOperatingMileage>
              </div>
            </dv-border-box-7>
          </div>
        </el-col>
      </el-row>
    </div>
  </ScreenAdapter>
</template>
<script>
import ScreenAdapter from '../../components/ScreenAdapter.vue'
import sevenOperatingTime from './components/sevenOperatingTime.vue'
import sevenOperatingMileage from './components/sevenOperatingMileage.vue'
import addOperatingMileage from './components/addOperatingMileage.vue'
import mapChart from './charts/map2'
import countNumber from './charts/countNumber'
import yesterdayCar from './charts/yesterdayCar'
import screenTable from './charts/screenTable'
import carImport from './charts/carImport'
import { getYesterdayOperationData } from '@/api/dataAnalysis/largeScreen'
export default {
  name: 'largeScreen',
  components: {
    ScreenAdapter,
    mapChart,
    sevenOperatingTime,
    sevenOperatingMileage,
    addOperatingMileage,
    countNumber,
    yesterdayCar,
    carImport,
    screenTable
  },
  data() {
    return {
      operationData: {}
    }
  },
  async mounted() {
    const { data } = await getYesterdayOperationData()
    this.operationData = data
  },
  methods: {}
}
</script>
<style scoped lang="less">
.dv-border-box-7 {
  box-shadow: none !important;
  border: none !important;
}

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

#largeScreen {
  .card_title {
    font-size: 21px;
    color: #fff;
    font-weight: 600;

    &::before {
      content: '';
      display: inline-block;
      margin-right: 13px;
      width: 18px;
      height: 16px;
      background: url('../../assets/screen/cardHead.png') center center;
      background-size: cover;
    }
  }

  .title {
    height: 243px;
    text-align: center;
    background: url('../../assets/screen/header.png') center center;
    background-size: cover;
  }

  .left {

    .l-top,
    .l-middle,
    .l-bottom {
      padding: 12px 22px;
    }

    .l-top {
      position: relative;
      background: url("../../assets/screen/operationRealityYes.png");
      background-size: 100% 100%;

      .inner {
        background: url('../../assets/screen/Group9.png');
        height: 78px;
        width: 188px;
        padding: 8px 15px;
        background-color: #13141a;
        font-size: 16px;
        font-weight: 600;
        background-size: cover;

        .name {
          color: #b8cdd9;

          &::before {
            content: '';
            margin-bottom: 2px;
            display: inline-block;
            margin-right: 13px;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background: linear-gradient(270deg, #00f6ff 0%, #1fffd7 100%);
          }
        }

        .text {
          color: #2bffff;
          float: right;
          font-size: 16px;
          font-family: Gen Jyuu Gothic;
        }

        .car {
          font-size: 12px;
          color: #b8cdd9;
          transform: scale(0.67);
          line-height: 6px;
          white-space: nowrap;
        }
      }

      .carOperation {
        height: 17px;
        font-family: GenJyuuGothic-ExtraLight;
        font-size: 8px;
        font-weight: 100;
        line-height: 26.85px;
        color: #54e5f8;
      }
    }

    .l-middle {
      background: url("../../assets/screen/operationRealityYes.png");
      background-size: 100% 100%;
    }

    .l-bottom {
      background: url("../../assets/screen/carInputYes.png");
      background-size: 100% 100%;
    }
  }

  .middle {
    .vector {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0px;
      width: 776px;
      height: 4px;
      background: linear-gradient(90deg, rgba(44, 255, 255, 0.00) -5%, rgba(44, 255, 255, 0.00) -5%, #1BF0CB 52%, rgba(44, 255, 255, 0.00) 100%, rgba(44, 255, 255, 0.00) 100%);
    }

    .img1 {
      /* 光效1 1 */

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 117px;
      width: 348px;
      height: 15px;
      background: url(../../assets/screen/backimg1.png);
    }

    .img2 {
      /* 光效2 1 */

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 104px;
      width: 1766px;
      height: 35px;
      background: url(../../assets/screen/backimg2.png);
    }

    .img3 {
      /* 光效3 1 */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 120px;
      width: 164px;
      height: 9px;
      background: url(../../assets/screen/backimg3.png);
    }

    .m-top {
      background: url(../../assets/screen/nationalVehicleDistribution.png);
      background-size: 100% 100%;
    }

    .m-bottom {
      padding: 12px 22px;
      background: url("../../assets/screen/vehicleWarning.png");
      background-size: 100% 100%;
    }
  }

  .right {

    .r-top,
    .r-middle,
    .r-bottom {
      padding: 12px;
      background: url("../../assets/screen/carInputYes.png");
      background-size: 100% 100%;
    }
  }
}
</style>
